import { fetchWrapper, objectToQueryParams } from '../utils';

export class admin {
  static baseUrl = process.env.REACT_APP_BASE_API + 'admin/products';

  static async findAll(filters) {
    const url = `${this.baseUrl}?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async findAllForSelect(filters) {
    const url = `${this.baseUrl}/for-select?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async create(form) {
    return await fetchWrapper.post(this.baseUrl, form);
  }

  static async update(form) {
    return await fetchWrapper.patch(this.baseUrl, form);
  }

  static async setStatus(form) {
    const url = `${this.baseUrl}/status`;
    return await fetchWrapper.patch(url, form);
  }

  static async destroy(userId) {
    return await fetchWrapper.delete(this.baseUrl, { id: userId });
  }
}
