import { useState } from 'react';
import { Button, Radio, RadioGroup } from '@nextui-org/react';
import { Constants } from '../../../utils';

const statuses = [
  { value: Constants.PURCHASE.STATUS.APPROVED.toString(), label: 'Emitida' },
  { value: Constants.PURCHASE.STATUS.REJECTED.toString(), label: 'Rechazada' },
  { value: Constants.PURCHASE.STATUS.ANALIZE.toString(), label: 'Por análisis' },
];

const PopStatus = ({ item, onSubmit }) => {
  const [form, setForm] = useState({
    id: item.id,
    status: String(item.status),
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const canSubmit = form.status !== (item.status || '');

  return (
    <div className="py-2 space-y-4">
      <RadioGroup
        label="Selecciona el estatus"
        value={form.status}
        onValueChange={v => onChange(v, 'status')}
      >
        {statuses.map(item => (
          <Radio value={item.value}>{ item.label }</Radio>
        ))}
      </RadioGroup>

      <Button
        radius="sm"
        fullWidth
        color={canSubmit ? 'primary':'default'}
        isDisabled={!canSubmit}
        onClick={() => onSubmit(form)}
      >
        Guardar
      </Button>
    </div>
  )
}

export default PopStatus;