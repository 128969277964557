/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Button, Checkbox, Divider, Input, ModalBody, ModalFooter, ModalHeader, Select, SelectItem, Slider, Textarea, Tooltip } from '@nextui-org/react';
import { IconFileTypePdf, IconInfinity, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { copy, fromPhotos } from '../../../utils';
import { ProductsService, ServicesService } from '../../../services';

export const CreateProduct = ({ product, onClose, onSuccess }) => {
  const { addPrice, addPriceProp, addRequiredDoc, addService, changeForm, changePrice, changePriceProp, changeRequiredDoc, changeService, form, isEditing, isLoading, prices, removePrice, removePriceProp, removeRequiredDoc, removeService, requiredDocs, services, servicesList, submit } = useForm(product);

  const onSubmit = async () => {
    const success = await submit();
    if (success) onSuccess();
  }

  useEffect(() => {
    const details_file = document.getElementById('details-file');
    const dropzone = document.getElementById('dropzone');

    dropzone.addEventListener('dragover', (e) => {
      e.preventDefault();
      dropzone.classList.add('!border-primary');
    });

    dropzone.addEventListener('dragleave', (e) => {
      e.preventDefault();
      dropzone.classList.remove('!border-primary');
    });

    dropzone.addEventListener('drop', (e) => {
      e.preventDefault();
      dropzone.classList.remove('!border-primary');
      changeForm(e.dataTransfer.files[0], 'details_file');
    });

    details_file.addEventListener('change', e => {
      changeForm(e.target.files[0], 'details_file');
      e.target.value = "";
    });

    if (!product) addPrice();

    return () => {
      dropzone.removeEventListener('dragover', () => {});
      dropzone.removeEventListener('dragleave', () => {});
      dropzone.removeEventListener('drop', () => {});
      details_file.removeEventListener('change', () => {});
    }
  }, []);

  return (
    <div id="modal-create-product">
      <ModalHeader>
        <h1>{isEditing ? 'Editar':'Crear'} Producto</h1>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full flex flex-col gap-y-4">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-y-4">
            <label className="flex items-center gap-2 cursor-pointer" htmlFor="main-photo">
              <Avatar size="lg" radius="sm" name="Foto" src={form.photoUrl} />
              <div className="flex flex-col">
                <small className="font-semibold">Foto del producto</small>
                <small>Subir imagen JPG o PNG</small>
                <small>máximo 318px por 200px</small>
              </div>
              <input
                id="main-photo"
                type="file"
                accept="image/*"
                hidden
                onChange={e => changeForm(e.target.files[0], 'photo')}
              />
            </label>

            <label className="flex md:flex-row-reverse items-center gap-2 cursor-pointer" htmlFor="central-icon">
              <Avatar size="lg" radius="sm" name="Foto" src={form.centralIconUrl} />
              <div className="flex flex-col">
                <small className="font-semibold">Banner central (App)</small>
                <small>Subir imagen JPG o PNG</small>
              </div>
              <input
                id="central-icon"
                type="file"
                accept="image/*"
                hidden
                onChange={e => changeForm(e.target.files[0], 'central_icon')}
              />
            </label>
          </div>

          <Input
            label="Nombre"
            variant="bordered"
            classNames={{ inputWrapper: 'border-1 h-12' }}
            value={form.name}
            onValueChange={v => changeForm(v, 'name')}
          />

          <Textarea
            label="Descripción"
            variant="bordered"
            classNames={{ inputWrapper: 'border-1 h-12' }}
            value={form.description}
            onValueChange={v => changeForm(v, 'description')}
          />

          <div className="flex items-center justify-between gap-4">
            <div>
              <Input
                label="Cantidad de Cuotas"
                variant="bordered"
                type="number"
                step="1"
                classNames={{ inputWrapper: 'border-1 h-12' }}
                value={form.fees}
                onValueChange={v => changeForm(v, 'fees')}
              />
            </div>

            <div className="flex flex-1 justify-end">
              <Tooltip content="Agregar precio" classNames={{ content: 'text-primary font-medium' }}>
                <Button color="primary" variant="flat" isIconOnly onClick={addPrice}>
                  <IconPlus color="hsl(var(--sq-primary-700))" />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="prices">
            <div className="price font-semibold">
              <div />
              <span>Prima Mensual</span>
              <span>Prima Anual</span>
              <div className="w-10" />
            </div>
            {prices.map((item, priceIdx) => (
              <div>
                <div className="price" key={priceIdx}>
                  <Input
                    label="Nombre"
                    variant="bordered"
                    className="input-1"
                    classNames={{ inputWrapper: 'border-1 h-12' }}
                    value={item.name}
                    onValueChange={v => changePrice(v, 'name', priceIdx)}
                  />

                  <Input
                    label={`Precio($) ${priceIdx + 1}`}
                    variant="bordered"
                    className="input-2"
                    classNames={{ inputWrapper: 'border-1 h-12' }}
                    value={item.price_monthly}
                    onValueChange={v => changePrice(v, 'price_monthly', priceIdx)}
                  />

                  <Input
                    label={`Precio($) ${priceIdx + 1}`}
                    variant="bordered"
                    className="input-3"
                    classNames={{ inputWrapper: 'border-1 h-12' }}
                    value={item.price_annual}
                    onValueChange={v => changePrice(v, 'price_annual', priceIdx)}
                  />

                  <div className="input-actions">
                    <Tooltip content="Eliminar precio">
                      <Button variant="flat" isIconOnly onClick={() => removePrice(priceIdx)}>
                        <IconX className="cursor-pointer" />
                      </Button>
                    </Tooltip>
                  </div>

                  <div className="flex gap-2">
                    <Checkbox
                      defaultValue={true}
                      isSelected={!item.insurability_age?.every(x => x === 0)}
                      onClick={() => {
                        const isEmpty = item.insurability_age?.every(x => x === 0);
                        const value = isEmpty ? [0,85] : [0,0];
                        changePrice(value, 'insurability_age', priceIdx);
                      }}
                    />
                    <Slider
                      label="Edad de asegurabilidad"
                      step={1}
                      minValue={0}
                      maxValue={100}
                      defaultValue={[0, 85]}
                      className="max-w-md"
                      size="sm"
                      value={item.insurability_age}
                      onChange={v => changePrice(v, 'insurability_age', priceIdx)}
                    />
                  </div>

                  <Input
                    label="Suma Asegurada"
                    type="number"
                    variant="bordered"
                    classNames={{ inputWrapper: 'border-1 h-12' }}
                    value={item.insured_sum}
                    onValueChange={v => changePrice(v, 'insured_sum', priceIdx)}
                  />

                  <Input
                    label="Deducible"
                    variant="bordered"
                    classNames={{ inputWrapper: 'border-1 h-12' }}
                    value={item.deductible}
                    onValueChange={v => changePrice(v, 'deductible', priceIdx)}
                  />
                </div>

                <div className="flex flex-1 items-center justify-between my-4">
                  <h1 className="flex-1 font-semibold">
                    Propiedades adicionales
                  </h1>

                  <div>
                    <Tooltip content="Agregar propiedad" classNames={{ content: 'text-primary font-medium' }}>
                      <Button color="primary" variant="flat" isIconOnly onClick={() => addPriceProp(priceIdx)}>
                        <IconPlus color="hsl(var(--sq-primary-700))" />
                      </Button>
                    </Tooltip>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  {item.additional_props.map((prop, propIdx) => (
                    <div key={propIdx} className="flex flex-1 items-center justify-between gap-4">
                      <Input
                        label="Nombre de propiedad"
                        variant="bordered"
                        classNames={{ inputWrapper: 'border-1 h-12' }}
                        value={prop.name}
                        onValueChange={v => changePriceProp(v, 'name', propIdx, priceIdx)}
                      />
                      <Input
                        label="Monto (opcional)"
                        type="number"
                        variant="bordered"
                        classNames={{ inputWrapper: 'border-1 h-12' }}
                        value={prop.insured_sum}
                        onValueChange={v => changePriceProp(v, 'insured_sum', propIdx, priceIdx)}
                      />

                      <div className="input-actions">
                        <Tooltip content="Eliminar propiedad">
                          <Button variant="flat" isIconOnly onClick={() => removePriceProp(propIdx, priceIdx)}>
                            <IconX className="cursor-pointer" />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>

                <Divider className="mt-4" />
              </div>
            ))}
          </div>

          <Input
            label="Plazo de espera para activación"
            variant="bordered"
            classNames={{ inputWrapper: 'border-1 h-12' }}
            value={form.activation_period}
            onValueChange={v => changeForm(v, 'activation_period')}
          />

          <div className="flex flex-1 items-center justify-between">
            <h1 className="flex-1 font-semibold text-primary">
              ¿Aplica solo para el titular?
            </h1>

            <div>
              <Tooltip content="¿Aplica solo para el titular?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.is_only_for_owner ? 'solid':'bordered'}
                  color={form.is_only_for_owner ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.is_only_for_owner, 'is_only_for_owner')}
                >
                  { form.is_only_for_owner ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <h1 className="flex-1 font-semibold text-primary">
              ¿Requiere Declaración de Salud Resumida?
            </h1>

            <div>
              <Tooltip content="Requiere DSR?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.apply_dsr ? 'solid':'bordered'}
                  color={form.apply_dsr ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(Number(!form.apply_dsr), 'apply_dsr')}
                >
                  { form.apply_dsr ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                ¿Aplica condiciones especiales para APS?
              </h1>
              <p className="text-sm text-gray-500">Condiciones especiales como: Solicitar al usuario Informe Médico, Cédula de Identidad y Récipe al pedir un servicio</p>
            </div>

            <div>
              <Tooltip content="¿Aplica solo para el titular?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.is_aps ? 'solid':'bordered'}
                  color={form.is_aps ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.is_aps, 'is_aps')}
                >
                  { form.is_aps ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                ¿Aplica condiciones especiales para RCV?
              </h1>
              <p className="text-sm text-gray-500">Condiciones especiales como: Permitir al usuario adquirir múltiples pólizas</p>
            </div>

            <div>
              <Tooltip content="¿Aplica solo para el titular?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.is_rcv ? 'solid':'bordered'}
                  color={form.is_rcv ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.is_rcv, 'is_rcv')}
                >
                  { form.is_rcv ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                ¿Aplica llamada telefónica?
              </h1>
              <p className="text-sm text-gray-500">Al solicitar un servicio muestra las instrucciones de la llamada</p>
            </div>

            <div>
              <Tooltip content="¿Aplica llamada telefónica?" classNames={{ content: 'text-primary font-medium' }}>
                <Button
                  variant={form.apply_call ? 'solid':'bordered'}
                  color={form.apply_call ? 'primary':'default'}
                  isIconOnly
                  onClick={() => changeForm(!form.apply_call, 'apply_call')}
                >
                  { form.apply_call ? 'Sí':'No' }
                </Button>
              </Tooltip>
            </div>
          </div>

          {form.apply_call && (
            <Textarea
              classNames={{ inputWrapper: 'border-1 h-12' }}
              label="Instrucciones"
              variant="bordered"
              value={form.instructions}
              onValueChange={v => changeForm(v, 'instructions')}
            />
          )}

          <Divider className="mt-2" />

          <div className="flex flex-1 items-center justify-between">
            <h1 className="flex-1 font-semibold text-primary">
              ¿Qué ofrece? (Servicios que ofrece)
            </h1>

            <div>
              <Tooltip content="Agregar servicio" classNames={{ content: 'text-primary font-medium' }}>
                <Button color="primary" variant="flat" isIconOnly onClick={addService}>
                  <IconPlus color="hsl(var(--sq-primary-700))" />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="services">
            {!!services.length && (
              <div className="header">
                <div>
                  <span className="font-medium">Icono</span>
                  <span className="text-xs font-normal leading-[14px]"> 800px * 800px</span>
                </div>
              </div>
            )}

            {services.map((item, index) => (
              <div className="service" key={index}>
                <label className="cursor-pointer" htmlFor={`service-photo-${index}`}>
                  <div>
                    <Avatar size="lg" radius="sm" name="Foto" src={item.photoUrl} />
                  </div>
                  <input
                    id={`service-photo-${index}`}
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={e => changeService(e.target.files[0], 'photo', index)}
                  />
                </label>

                <Select
                  label="Servicio / Cobertura"
                  variant="bordered"
                  className="input-1"
                  classNames={{ trigger: 'border-1 h-12' }}
                  disallowEmptySelection={true}
                  selectedKeys={!!servicesList.length ? [item.service_id] : []}
                  onSelectionChange={v => changeService(v.currentKey, 'service_id', index)}
                >
                  {servicesList.map((item) => (
                    <SelectItem key={item.value} value={item.value}>
                      { item.label }
                    </SelectItem>
                  ))}
                </Select>

                <Input
                  label="Cantidad de usos"
                  variant="bordered"
                  className="input-2"
                  classNames={{ inputWrapper: 'border-1 h-12' }}
                  value={item.uses_quantity}
                  onValueChange={v => changeService(v, 'uses_quantity', index)}
                  isDisabled={item.is_unlimited}
                />

                <div className="input-actions">
                  <div>
                    <Tooltip content="Es ilimitado">
                      <Button
                        variant={item.is_unlimited ? 'solid':'bordered'}
                        color={item.is_unlimited ? 'primary':'default'}
                        isIconOnly
                        onClick={() => changeService(!item.is_unlimited, 'is_unlimited', index)}
                      >
                        <IconInfinity />
                      </Button>
                    </Tooltip>
                  </div>

                  <div>
                    <Tooltip content="Eliminar servicio">
                      <Button variant="flat" isIconOnly onClick={() => removeService(index)}>
                        <IconX />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Divider className="mt-2" />

          <div className="flex flex-1 items-center justify-between">
            <div>
              <h1 className="flex-1 font-semibold text-primary">
                Documentos requeridos (Opcional)
              </h1>
              <small>Aparecerán en el detalle del producto (App)</small>
            </div>

            <div>
              <Tooltip content="Agregar documento" classNames={{ content: 'text-primary font-medium' }}>
                <Button color="primary" variant="flat" isIconOnly onClick={addRequiredDoc}>
                  <IconPlus color="hsl(var(--sq-primary-700))" />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="required-documents">
            {requiredDocs.map((item, index) => (
              <div className="document" key={index}>
                <Input
                  label="Nombre del documento"
                  variant="bordered"
                  className="input-1"
                  classNames={{ inputWrapper: 'border-1 h-12' }}
                  value={item.name}
                  onValueChange={v => changeRequiredDoc(v, 'name', index)}
                />

                <div className="input-actions flex gap-4 items-center">
                  <Tooltip content="¿Es opcional?">
                    <Button
                      variant={item.is_optional ? 'solid':'bordered'}
                      color={item.is_optional ? 'primary':'default'}
                      isIconOnly
                      onClick={() => changeRequiredDoc(Number(!item.is_optional), 'is_optional', index)}
                    >
                      { item.is_optional ? 'Sí':'No' }
                    </Button>
                  </Tooltip>
                  <Tooltip content="Eliminar documento">
                    <Button variant="flat" isIconOnly onClick={() => removeRequiredDoc(index)}>
                      <IconX className="cursor-pointer" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>

          <Divider className="mt-2" />

          <div id="dropzone" className="dropzone">
            <div className="text-center">
              <IconFileTypePdf className="mx-auto h-12 w-12" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                <input id="details-file" accept="application/pdf" type="file" hidden value="" onChange={() => {}} />

                {!!form?.details_file ? (
                  <>
                    <IconTrash
                      className="cursor-pointer absolute top-2 right-2"
                      onClick={() => changeForm('','details_file')}
                    />
                    <p className="text-center text-sm font-medium text-gray-900">
                      { form?.details_file?.name || 'Documento.pdf' }
                    </p>
                  </>
                ) : (
                  <label className="relative" htmlFor="details-file">
                    <span>Suelta aquí</span>
                    <span className="text-primary cursor-pointer"> o busca </span>
                    <span>el archivo</span>
                  </label>
                )}
              </h3>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-end">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          color="primary"
          onPress={onSubmit}
        >
          Guardar
        </Button>
      </ModalFooter>
    </div>
  )
}

const useForm = (product) => {
  const INITIAL_PRICE_PROP = {
    name: '',
    insured_sum: '',
  };

  const INITIAL_PRICE = {
    name: '',
    price_monthly: '',
    price_annual: '',
    insurability_age: [0,85],
    insured_sum: '',
    deductible: '',
    additional_props: [],
  };

  const INITIAL_SERVICES = {
    photo: '',
    photoUrl: '',
    service_id: '',
    uses_quantity: '',
    is_unlimited: false,
  };

  const INITIAL_REQ_DOC = {
    name: '',
    is_optional: 0,
  };

  const INITIAL_FORM = {
    photo: '',
    photoUrl: '',
    details_file: '',
    name: '',
    description: '',
    fees: '',
    activation_period: '',
    is_only_for_owner: false,
    instructions: '',
    apply_call: false,
    apply_dsr: true,
    is_aps: false,
    is_rcv: false,
  };

  const isEditing = useRef(!!product);
  const [canFetch, setCanFetch] = useState(true);
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [prices, setPrices] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [requiredDocs, setRequiredDocs] = useState([]);

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const fillForm = () => {
    setForm({
      id: product.id,
      photo: product.photo,
      photoUrl: product.photo ? fromPhotos(product.photo) : '',
      central_icon: product.central_icon,
      centralIconUrl: product.central_icon ? fromPhotos(product.central_icon) : '',
      details_file: product.details_file,
      name: product.name,
      description: product.description,
      fees: product.fees,
      activation_period: product.activation_period || '',
      instructions: product.instructions || '',
      apply_dsr: product.apply_dsr ?? true,
      apply_call: product.apply_call || false,
      is_only_for_owner: product.is_only_for_owner || false,
      is_aps: product.is_aps || false,
      is_rcv: product.is_rcv || false,
    });

    setServices(product.services?.map(x => ({
      ...x,
      service_id: String(x.service_id),
      photoUrl: x.photo ? fromPhotos(x.photo) : '',
      uses_quantity: x.is_unlimited ? '' : x.uses_quantity,
    })) || []);

    setPrices(product.prices?.map(x => ({
      ...x,
      insurability_age: String(x.insurability_age).includes('-')
        ? String(x.insurability_age).split('-')
        : [0,0],
    })) || []);
    setRequiredDocs(product.required_docs || []);
  }

  const getServices = async () => {
    try {
      const response = await ServicesService.admin.findAllForSelect();
      setServicesList(response.map(x => ({ value: String(x.id), label: x.name })));

    } catch (error) {
      setServicesList([]);
      onError(String(error));
    }
  }

  const changeForm = (value, target) => {
    setForm(s => ({
      ...s,
      photoUrl: target === 'photo' && !!value ? URL.createObjectURL(value) : s.photoUrl,
      centralIconUrl: target === 'central_icon' && !!value ? URL.createObjectURL(value) : s.centralIconUrl,
      [target]: value,
    }));
  }

  const changeService = (value, target, index) => {
    setServices(s => {
      const services = s.map(x => Object.assign({}, x));
      services[index][target] = value;

      if (target === 'photo') {
        services[index].photoUrl = URL.createObjectURL(value);
      }

      if (target === 'is_unlimited' && value) {
        services[index].uses_quantity = '';
      }

      return services;
    });
  }

  const changePrice = (value, target, index) => {
    setPrices(s => {
      const prices = copy(s);
      prices[index][target] = value;
      return prices;
    });
  }

  const changePriceProp = (value, target, propIdx, priceIdx) => {
    setPrices(s => {
      const prices = copy(s);
      prices[priceIdx].additional_props[propIdx][target] = value;
      return prices;
    });
  }

  const changeRequiredDoc = (value, target, index) => {
    setRequiredDocs(s => {
      const docs = copy(s);
      docs[index][target] = value;
      return docs;
    });
  }

  const addPrice = () => {
    setPrices(s => ([ ...s, { ...INITIAL_PRICE }]));
  }

  const addPriceProp = (priceIdx) => {
    setPrices(s => {
      const prices = copy(s);
      prices[priceIdx].additional_props?.push({ ...INITIAL_PRICE_PROP });
      return prices;
    });
  }

  const addService = () => {
    setServices(s => ([ ...s, { ...INITIAL_SERVICES }]));
  }

  const addRequiredDoc = () => {
    setRequiredDocs(s => ([ ...s, { ...INITIAL_REQ_DOC }]));
  }

  const removeService = (index) => {
    const others = services.filter((_, idx) => idx !== index);
    setServices(others);
  }

  const removePrice = (index) => {
    const others = prices.filter((_, idx) => idx !== index);
    setPrices(others);
  }

  const removePriceProp = (propIdx, priceIdx) => {
    setPrices(s => {
      const prices = copy(s);
      const priceProps = prices[priceIdx].additional_props;
      prices[priceIdx].additional_props = priceProps?.filter((_, idx) => idx !== propIdx) || [];
      return prices;
    });
  }

  const removeRequiredDoc = (index) => {
    const others = requiredDocs.filter((_, idx) => idx !== index);
    setRequiredDocs(others);
  }

  const isValidForm = () => {
    if (!form.name)
      return onError('El nombre es obligatorio');

    if (!form.description)
      return onError('La descripción es obligatoria');

    if (!form.activation_period)
      return onError('El plazo de espera de activación es obligatorio');

    if (!services.length)
      return onError('Debe agregar al menos un servicio');

    if (services.some(x => !x.service_id || !x.photoUrl || (!x.uses_quantity && !x.is_unlimited)))
      return onError('Debe completar todos los campos de los servicios');

    if (!prices.length)
      return onError('Debe agregar al menos un precio');

    if (!!prices?.length && prices.some(x => !x.name))
      return onError('Debe completar el nombre del precio');

    if (!!prices?.length && prices.some(x => !x.price_monthly && !x.price_annual))
      return onError('Debe definir al menos uno de los precios (prima mensual o prima anual) en el precio');

    if (!!prices?.length && prices.some(x => x.additional_props.length && x.additional_props.some(p => !String(p.name).trim())))
      return onError('Debe definir el nombre de todas las propiedades adicionales en el precio');

    if (!!requiredDocs?.length && requiredDocs.some(x => !x.name))
      return onError('Debe completar el nombre del documento');

    if (!form.details_file)
      return onError('El PDF es obligatorio');

    return true;
  }

  const submit = async () => {
    if (!canFetch || !isValidForm()) return;
    setCanFetch(false);

    try {
      const data = {
        ...form,
        is_only_for_owner: Number(form.is_only_for_owner),
        apply_call: Number(form.apply_call),
        apply_dsr: Number(form.apply_dsr),
        is_aps: Number(form.is_aps),
        is_rcv: Number(form.is_rcv),
        required_docs: JSON.stringify(requiredDocs),
        hasFile: true,
      };

      data.prices = JSON.stringify(prices.map(x => ({
        ...x,
        id: x?.id || undefined,
        insured_sum: x.insured_sum || 0,
        insurability_age: typeof x.insurability_age === 'string'
          ? x.insurability_age
          : x.insurability_age?.join('-'),
      })));

      data.services = JSON.stringify(services.map(x => ({
        id: x?.id || undefined,
        service_id: x.service_id,
        uses_quantity: x.uses_quantity,
        is_unlimited: x.is_unlimited,
        photo: '',
      })));

      services.forEach((x, index) => {
        data[`service_photo_${index}`] = x.photo;
      });

      isEditing.current
        ? await ProductsService.admin.update(data)
        : await ProductsService.admin.create(data);
      toast.success(`Producto ${isEditing.current ? 'actualizado':'creado'} con éxito`);
      setCanFetch(true);
      return true;

    } catch (error) {
      onError(String(error));
      setCanFetch(true);
      return false;
    }
  }

  useEffect(() => {
    if (isEditing.current) fillForm();
    getServices();
  }, []);

  return {
    addPrice,
    addPriceProp,
    addRequiredDoc,
    addService,
    changeForm,
    changePrice,
    changePriceProp,
    changeRequiredDoc,
    changeService,
    form,
    isEditing: isEditing.current,
    isLoading: !canFetch,
    prices,
    removePrice,
    removePriceProp,
    removeRequiredDoc,
    removeService,
    requiredDocs,
    services,
    servicesList,
    submit,
  };
}