import { useContext } from 'react';
import { Navigate, Route, RouterProvider, Routes, createHashRouter } from 'react-router-dom';
import { AuthContext } from '../context';
import Login from '../screens/auth/login';
import Logout from '../screens/auth/logout';
import RecoverPassword from '../screens/auth/recover-password';
import Home from '../screens/admin/home/home';
import Users from '../screens/admin/users/users';
import Moderators from '../screens/admin/moderators/moderators';
import Products from '../screens/admin/products/products';
import Orders from '../screens/admin/orders/orders';
import Services from '../screens/admin/services/services';
import Payments from '../screens/admin/payments/payments';
import Subscriptions from '../screens/admin/subscriptions/subscriptions';
import Profile from '../screens/admin/profile/profile';
import Layout from './layout';

const Router = () => {
  const { isLogged } = useContext(AuthContext);

  const authRouter = createHashRouter([
    {
      index: true,
      path: '/',
      element: <Login />,
    },
    {
      path: '/recover/:code',
      element: <RecoverPassword />,
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    }
  ]);

  const adminRouter = createHashRouter([
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      index: true,
      path: '*',
      Component: AdminRoutes,
    },
  ]);

  return (
    <RouterProvider router={isLogged ? adminRouter : authRouter} />
  )
}

const AdminRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/" element={<Home />} />
      <Route path="/productos" element={<Products />} />
      <Route path="/moderadores" element={<Moderators />} />
      <Route path="/usuarios" element={<Users />} />
      <Route path="/solicitudes" element={<Orders />} />
      <Route path="/servicios" element={<Services />} />
      <Route path="/pagos" element={<Payments />} />
      <Route path="/suscripciones" element={<Subscriptions />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default Router;